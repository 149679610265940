import { CALL_STATUS, RepresentativeStatus } from 'common.interfaces';

export const findMatchingStatusName = (
  liveStatus: RepresentativeStatus & { status_name: string },
): CALL_STATUS | undefined => {
  return Object.values(CALL_STATUS).find(
    (callStatus: CALL_STATUS) =>
      callStatus === liveStatus.status_name || callStatus.startsWith(liveStatus.status_name.substring(0, 16)),
  );
};

export const convertStatusName = (
  liveStatuses: Array<RepresentativeStatus & { status_name: string }>,
): RepresentativeStatus[] => {
  return liveStatuses.map((liveStatus) => {
    const matchingStatusName = findMatchingStatusName(liveStatus);

    if (!matchingStatusName) {
      console.error(`Unknown Talkdesk status name: ${liveStatus.status_name}, falling back to OFFLINE status`);
    }

    return { ...liveStatus, status_name: matchingStatusName ?? CALL_STATUS.OFFLINE };
  });
};
