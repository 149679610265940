import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { LiveStatusService } from '@origin8-web/core-ui/live-status';
import { inject } from '@angular/core';
import { SnackMessageService } from '@origin8-web/core-ui/snack';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { EMPTY, pipe, retry, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { RepresentativeProfile, RepresentativeStatus } from 'common.interfaces';
import { LiveStatusState } from '../../../app.interface';
import { AdvocateServiceGateway } from '../../../service-gateways/advocate/advocate.service-gateway';

const initialState: LiveStatusState = {
  representativeStatuses: [],
  isLoadingRepresentativesStatuses: true,
  representativesProfiles: [],
  isLoadingRepresentativesProfiles: true,
};
const TEN_SECONDS = 10000; /* in ms */

export const LiveStatusStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(
    (
      store,
      liveStatusService = inject(LiveStatusService),
      advocateServiceGateway = inject(AdvocateServiceGateway),
      snackMessageService = inject(SnackMessageService),
    ) => {
      return {
        loadLiveStatuses: rxMethod<void>(
          pipe(
            tap(() => patchState(store, { isLoadingRepresentativesStatuses: true })),
            switchMap(() => {
              return liveStatusService.getAdvocatesLiveStatus();
            }),
            tapResponse({
              next: (advocatesStatus: RepresentativeStatus[]) =>
                patchState(store, { representativeStatuses: advocatesStatus, isLoadingRepresentativesStatuses: false }),
              error: (e) => {
                patchState(store, { isLoadingRepresentativesStatuses: false });
                console.error(`An error occurred while loading live statuses`, e);
                snackMessageService.showError('An error occurred while loading live status, please reload the page.');
              },
            }),
          ),
        ),
        loadRepresentativesProfiles: rxMethod<void>(
          pipe(
            tap(() => patchState(store, { isLoadingRepresentativesProfiles: true })),
            switchMap(() => {
              return advocateServiceGateway
                .getAllRepresentativeProfiles()
                .pipe(retry({ count: 2, delay: TEN_SECONDS }));
            }),
            tapResponse({
              next: (userProfiles: RepresentativeProfile[]) =>
                patchState(store, { representativesProfiles: userProfiles, isLoadingRepresentativesProfiles: false }),
              error: (e) => {
                patchState(store, { isLoadingRepresentativesProfiles: false });
                console.error(e);
                snackMessageService.showError('An error occurred while retrieving user profiles');
                return EMPTY;
              },
            }),
          ),
        ),
      };
    },
  ),
);
